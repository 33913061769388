@tailwind base;
@tailwind components;
@tailwind utilities;

.payments-title {
  @apply text-3xl text-gray-600 text-center lg:text-left;
}

.section-grey {
  @apply bg-gray-100 py-16 mb-16;
}

/* swiper.js styles, used for carousels */

.swiper-pagination-bullets {
  display: none;
}

.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  --swiper-theme-color: orange;
}

.swiper-button-disabled {
  display: none;
}

/* dark class for carousel components that have a dark image behind */

.dark-bg .text-gray-700,
.dark-bg .text-gray-800 {
  color: white;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.8);
}

/* mobile menu styles when open class is appended */

.mobile-menu,
.mobile-menu-overlay {
  transition: 0.2s ease;
  pointer-events: none;
}

.mobile-menu.open,
.mobile-menu-overlay.open {
  pointer-events: auto;
}

.mobile-menu {
  transform: translateX(100%);
  overflow: hidden;
}

.mobile-menu.open {
  transform: translate(0);
}

.mobile-menu-overlay {
  opacity: 0;
}

.mobile-menu-overlay.open {
  opacity: 1;
  pointer-events: auto;
}
